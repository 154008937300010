import React from 'react';
import { Stack, Typography } from '@mui/material';

const ForecastHeaderDetails = ({
  headerDetailsClassName,
  title,
  subtitle,
  isDesktop,
}: {
  headerDetailsClassName?: string;
  title?: string;
  subtitle?: string;
  isDesktop: boolean;
}) => (
  <Stack className={headerDetailsClassName} direction="column">
    <Stack direction="row" alignItems="baseline">
      <Typography
        color="text.brandSecondary"
        component="h1"
        data-testid="forecast-header-title"
        textTransform="capitalize"
        variant={isDesktop ? 'h1' : 'h2'}
        mb={1}
      >
        {title}
      </Typography>
    </Stack>
    <Typography
      color="textSecondary"
      component="h2"
      data-testid="forecast-header-subtitle"
      variant="overline2"
    >
      {subtitle}
    </Typography>
  </Stack>
);

export default ForecastHeaderDetails;
