import React, { Component, type MouseEvent, type ReactNode } from 'react';
import classNames from 'classnames';
import { Button, Dialog, Typography } from '@mui/material';
import { RouterContext } from 'next/dist/shared/lib/router-context';
import { trackEvent } from '@surfline/web-common';
import theme from '@wavetrak/theme';

import { CloseIcon, Search, SurflineLogo, SurflineWaterDrop } from 'components/Icons';
import TrackableLink from 'components/TrackableLink';
import WavetrakLink from 'components/WavetrakLink';
import type { MenuState, ServiceConfiguration, TaxonomyNavigatorSettings } from 'types/header';
import type { UserDetails, UserSettings } from 'types/user';
import createAccessibleOnClick from 'utils/createAccessibleOnClick';

import CamsReportsMenu from './components/CamsReportsMenu';
import NavigationItem from './components/NavigationItem';
import NewsMenu from './components/NewsMenu';
import SiteSearch from './components/SiteSearch';
import UserAvatar from './components/UserAvatar';
import UserMenu from './components/UserMenu';
import isPremiumOrVIP from '../helpers/isPremiumOrVip';
import isForecastOnlyEntitled from '../helpers/isForecastMember';
import getSignInHref from '../helpers/getSignInHref';
import { isPremiumPlusEntitled } from '../helpers/isPremiumEntitled';

import styles from './NavigationBar.module.scss';

const SUBSCRIBE_EVENT_PROPERTIES = { location: 'button top' };
const DESKTOP_BREAKPOINT = 992;

export interface NavigationBarProps {
  accountAlert?: boolean | null;
  accountWarning?: boolean;
  badge?: ReactNode;
  conditionColorsClass?: string;
  entitlements?: Array<string>;
  freeTrialEligible?: boolean;
  hideCTA?: boolean;
  large?: boolean;
  serviceConfig: ServiceConfiguration;
  settings?: TaxonomyNavigatorSettings;
  user?: Partial<UserDetails> | null;
  userSettings: UserSettings | null;
  latestFeatureRelease?: {
    title: string;
    date: string;
    url?: string;
  };
  latestPremiumPerkRelease?: {
    date: string;
    url?: string;
  };
  isPremiumPlusSplitEnabled?: boolean;
  sharedSubscriptionsEnabled?: boolean;
}

interface NavigationBarState {
  camsReportsMenuState: MenuState;
  forecastsMenuState: MenuState;
  mobileBreakpoint: number;
  mobileMenuTitle: any;
  renderMobileMenu: any;
  searchIsOpen: boolean;
  signInHref: string;
  viewport: any;
}

export default class NavigationBar extends Component<NavigationBarProps, NavigationBarState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    accountAlert: false,
    accountWarning: false,
    badge: null,
    entitlements: [],
    freeTrialEligible: false,
    hideCTA: false,
    large: false,
    settings: {},
    user: null,
    latestFeatureRelease: {},
  };

  static getSignInEventProperties = () => ({
    pageName: window.location.pathname,
    tabName: 'Sign In',
    linkName: 'Sign In',
    platform: 'web',
    isMobileView: window.innerWidth <= theme.breakpoints.values.tabletLg,
  });

  static getUpgradeCTA = (
    isForecastMember: boolean,
    isFreeTrialEligible?: boolean,
    user?: Partial<UserDetails> | null,
    isPremiumUserWithSplitEnabled?: boolean,
  ) => {
    if (isForecastMember || isPremiumUserWithSplitEnabled) return 'Upgrade now';
    return !user || isFreeTrialEligible ? 'Start Free Trial' : 'Go Premium';
  };

  mobileMenuRef: any;

  resizeTimeout: any;

  signInLinkRef: any;

  constructor(props: NavigationBarProps) {
    super(props);

    this.state = {
      camsReportsMenuState: {
        error: false,
        loading: false,
        taxonomy: {
          activeNode: {},
          children: [],
          parents: [],
        },
      },
      forecastsMenuState: {
        error: false,
        loading: false,
        taxonomy: {
          activeNode: {},
          children: [],
          parents: [],
        },
      },
      mobileBreakpoint: DESKTOP_BREAKPOINT,
      mobileMenuTitle: null,
      renderMobileMenu: null,
      signInHref: `/sign-in`,
      searchIsOpen: false,
      viewport: null,
    };

    this.mobileMenuRef = null;
    this.resizeTimeout = null;
    this.signInLinkRef = React.createRef();
  }

  componentDidMount() {
    this.setViewport();
    const router = this.context;
    this.setState({ signInHref: getSignInHref(router.asPath) });
    window?.addEventListener('resize', this.resizeListener);
  }

  componentDidUpdate(prevProps: NavigationBarProps, prevState: NavigationBarState) {
    const router = this.context;
    const signInHref = getSignInHref(router.asPath);
    if (signInHref !== prevState.signInHref) {
      this.setState({
        signInHref,
      });
    }
  }

  componentWillUnmount() {
    window?.removeEventListener('resize', this.resizeListener);
    this.removeMobileMenu();
  }

  setViewport = () => {
    this.setState((prevState) => ({
      viewport: window?.innerWidth > prevState.mobileBreakpoint ? 'desktop' : 'mobile',
    }));
  };

  setMenuState = (menuType: string) => (stateUpdate: MenuState) => {
    this.setState(
      (prevState: Readonly<NavigationBarState>) =>
        ({
          [menuType]: {
            ...(prevState as any)[menuType],
            ...stateUpdate,
          },
        } as any),
    );
  };

  setMobileMenu = (renderComponent: () => ReactNode, title: string) => {
    const { viewport } = this.state;
    if (viewport === 'mobile') {
      this.setState({
        renderMobileMenu: renderComponent,
        mobileMenuTitle: title,
      });
    }
  };

  removeMobileMenu = () => {
    this.setState({
      renderMobileMenu: null,
      searchIsOpen: false,
    });
  };

  resizeListener = () => {
    if (this.resizeTimeout) return;
    this.resizeTimeout = setTimeout(() => {
      this.resizeTimeout = null;
      this.updateScrollLock();
    }, 66);

    const { viewport, mobileBreakpoint } = this.state;
    if (viewport === 'mobile' && window.innerWidth > mobileBreakpoint) {
      this.setState({ viewport: 'desktop' });
    } else if (viewport === 'desktop' && window.innerWidth <= mobileBreakpoint) {
      this.setState({ viewport: 'mobile' });
    }
  };

  scrollMenuToTop = () => {
    if (this.mobileMenuRef) {
      this.mobileMenuRef.scrollTop = 0;
    }
  };

  updateScrollLock = () => {
    const { renderMobileMenu } = this.state;
    if (renderMobileMenu && window.innerWidth > DESKTOP_BREAKPOINT) {
      this.removeMobileMenu();
    }
  };

  handleClickedLink = (clickProperties: any, closeMobileMenu: boolean = false) => {
    if (clickProperties) {
      const { name, properties } = clickProperties;
      trackEvent(name, properties);
    }
    if (closeMobileMenu) {
      this.removeMobileMenu();
    }
  };

  handleCloseSearch = () => this.setState({ searchIsOpen: false });

  handleClickAccount = (event: MouseEvent) => {
    const { viewport } = this.state;
    if (viewport === 'mobile') event.preventDefault();
    this.setMobileMenu(this.renderUserMenu, 'Account');
  };

  renderCamsReportsMenu = () => {
    const { camsReportsMenuState, signInHref } = this.state;
    const { user, settings, serviceConfig, userSettings } = this.props;
    return (
      <CamsReportsMenu
        loggedIn={!!user}
        navigationSettings={settings?.navigation}
        recentlyVisited={settings?.recentlyVisited?.spots}
        serviceConfig={serviceConfig}
        menuState={camsReportsMenuState}
        setMenuState={this.setMenuState('camsReportsMenuState')}
        scrollMenuToTop={this.scrollMenuToTop}
        signInUrl={signInHref}
        onClickLink={this.handleClickedLink}
        userSettings={userSettings}
      />
    );
  };

  renderUserMenu = () => {
    const {
      user,
      entitlements,
      freeTrialEligible,
      accountWarning,
      userSettings,
      latestFeatureRelease,
      latestPremiumPerkRelease,
      isPremiumPlusSplitEnabled,
      sharedSubscriptionsEnabled,
    } = this.props;
    const showFeatureReleaseNotification = latestFeatureRelease?.date
      ? userSettings?.releaseAnnouncementDate !== latestFeatureRelease?.date
      : false;
    const showPremiumPerksNotification = latestPremiumPerkRelease?.date
      ? userSettings?.premiumPerkDate !== latestPremiumPerkRelease?.date
      : false;
    return (
      <UserMenu
        user={user}
        entitlements={entitlements}
        freeTrialEligible={freeTrialEligible}
        accountWarning={accountWarning}
        showDetails
        onClickLink={this.handleClickedLink}
        featureRelease={latestFeatureRelease}
        premiumPerks={latestPremiumPerkRelease}
        showFeatureReleaseNotification={showFeatureReleaseNotification}
        showPremiumPerksNotification={showPremiumPerksNotification}
        isPremiumPlusSplitEnabled={isPremiumPlusSplitEnabled}
        sharedSubscriptionsEnabled={sharedSubscriptionsEnabled}
      />
    );
  };

  renderNewsMenu = () => <NewsMenu onClickLink={this.handleClickedLink} />;

  handleClickTravel = () =>
    this.handleClickedLink({
      name: 'Clicked Main Nav',
      properties: {
        completion: true,
        destinationUrl: '/travel',
        linkName: 'travel',
        tabName: 'travel',
        clickEndLocation: 'travel',
      },
    });

  handleClickTrial = () =>
    this.handleClickedLink(
      { name: 'Clicked Subscribe CTA', properties: SUBSCRIBE_EVENT_PROPERTIES },
      true,
    );

  handleClickReports = (event: MouseEvent) => {
    event.preventDefault();
    this.setMobileMenu(this.renderCamsReportsMenu, 'Cams');
  };

  handleClickStories = (event: MouseEvent) => {
    event.preventDefault();
    this.setMobileMenu(this.renderNewsMenu, 'Stories');
  };

  render() {
    const { renderMobileMenu, mobileMenuTitle, searchIsOpen, signInHref, viewport } = this.state;
    const {
      accountAlert,
      accountWarning,
      conditionColorsClass,
      entitlements,
      freeTrialEligible,
      hideCTA,
      latestFeatureRelease,
      latestPremiumPerkRelease,
      serviceConfig,
      user,
      userSettings,
      isPremiumPlusSplitEnabled,
    } = this.props;

    const isForecastMember = isForecastOnlyEntitled(entitlements);
    const showFeatureReleaseNotification = latestFeatureRelease?.date
      ? userSettings?.releaseAnnouncementDate !== latestFeatureRelease?.date
      : false;
    const showPremiumPerksNotification = latestPremiumPerkRelease?.date
      ? userSettings?.premiumPerkDate !== latestPremiumPerkRelease?.date
      : false;
    const isPremiumPlusUser = isPremiumPlusEntitled(entitlements);
    const isPremiumUserWithSplitEnabled = isForecastMember && isPremiumPlusSplitEnabled;
    const userAvatar = user ? (
      <UserAvatar
        accountAlert={accountAlert}
        accountWarning={accountWarning}
        isPremiumPlusUser={isPremiumPlusUser}
        showNotification={showFeatureReleaseNotification || showPremiumPerksNotification}
      />
    ) : null;

    return (
      <header className={classNames('quiver-new-navigation-bar', styles.navigationBar)}>
        <div className={styles.content}>
          <div className={styles.logoDesktop}>
            <WavetrakLink href="/" isExternal>
              <SurflineLogo />
            </WavetrakLink>
          </div>
          <div className={styles.logoMobile}>
            <WavetrakLink href="/" isExternal>
              <SurflineWaterDrop />
            </WavetrakLink>
          </div>
          <div className={styles.navigation}>
            <NavigationItem linkDisplay="Cams & Forecasts" href="/surf-reports-forecasts-cams">
              <div className={classNames(styles.menuWrapper, 'sl-menu-wrapper')}>
                {this.renderCamsReportsMenu()}
              </div>
            </NavigationItem>
            <NavigationItem linkDisplay="Stories" href="/surf-news">
              <div className={classNames(styles.menuWrapper, 'sl-menu-wrapper')}>
                <NewsMenu onClickLink={this.handleClickedLink} />
              </div>
            </NavigationItem>
            <NavigationItem linkDisplay="Travel" href="/travel" onClick={this.handleClickTravel}>
              <div className={classNames(styles.menuWrapper, 'sl-menu-wrapper')} />
            </NavigationItem>
          </div>
          <div className={styles.siteSearch}>
            <div
              {...createAccessibleOnClick(() => this.setState({ searchIsOpen: true }), 'button')}
              className={styles.searchButton}
            >
              <Search className={styles.searchButtonIcon} />
              <Typography variant="footnote" component="span">
                Search
              </Typography>
            </div>
            <SiteSearch
              closeSearch={this.handleCloseSearch}
              serviceUrl={serviceConfig?.serviceUrl ?? ''}
              searchIsOpen={searchIsOpen}
              viewport={viewport}
            />
          </div>
          <div className={styles.cta}>
            {!hideCTA &&
            (!user || !isPremiumOrVIP(entitlements) || isPremiumUserWithSplitEnabled) ? (
              <Button
                className={styles.ctaLink}
                data-testid="navbar-trial-cta"
                href="/upgrade"
                onClick={this.handleClickTrial}
                size="small"
                variant="primary"
              >
                {NavigationBar.getUpgradeCTA(
                  isForecastMember,
                  freeTrialEligible,
                  user,
                  isPremiumUserWithSplitEnabled,
                )}
              </Button>
            ) : null}
          </div>
          {user ? (
            <div className={styles.account}>
              <NavigationItem
                testId="navigation-item-user"
                href="/account"
                isAvatar
                isExternal
                linkDisplay={userAvatar}
                onClick={this.handleClickAccount}
                role="button"
                tabIndex={0}
              >
                <div className={classNames(styles.menuWrapper, 'sl-menu-wrapper')}>
                  {this.renderUserMenu()}
                </div>
              </NavigationItem>
            </div>
          ) : (
            <TrackableLink
              eventName="Clicked Main Nav"
              eventProperties={NavigationBar.getSignInEventProperties}
              ref={this.signInLinkRef}
            >
              <Typography
                data-testid="navbar-signin"
                variant="subHeadline"
                component="a"
                ref={this.signInLinkRef}
                href={signInHref}
                className={styles.signin}
              >
                Sign In
              </Typography>
            </TrackableLink>
          )}
        </div>
        <div className={styles.mobile}>
          <div className={styles.mobileContent}>
            <div className={styles.mobileContainer}>
              <div className={styles.mobileNav} data-testid="mobile-nav">
                <Dialog open={renderMobileMenu !== null}>
                  {renderMobileMenu !== null && (
                    <div className={classNames(styles.mobileNavMenu, conditionColorsClass)}>
                      <div className={styles.mobileNavMenuInner}>
                        <a
                          className={styles.mobileNavMenuClose}
                          data-testid="mobile-nav-close"
                          {...createAccessibleOnClick(
                            () =>
                              this.handleClickedLink(
                                {
                                  name: 'Clicked Main Nav',
                                  properties: {
                                    completion: false,
                                    linkName: 'close',
                                    tabName: mobileMenuTitle,
                                  },
                                },
                                true,
                              ),
                            'button',
                          )}
                        >
                          <span />
                          <Typography
                            className={styles.mobileNavMenuCloseTitle}
                            component="span"
                            data-testid="mobile-nav-title"
                            variant="subHeadline"
                          >
                            {mobileMenuTitle}
                          </Typography>
                          <CloseIcon />
                        </a>
                        {renderMobileMenu()}
                      </div>
                    </div>
                  )}
                </Dialog>
                <NavigationItem
                  href="/surf-reports-forecasts-cams"
                  linkDisplay="Cams & Forecasts"
                  onClick={this.handleClickReports}
                  role="button"
                  tabIndex={0}
                  testId="mobile-navigation-item"
                />
                <NavigationItem
                  href="/surf-news"
                  linkDisplay="Stories"
                  onClick={this.handleClickStories}
                  role="button"
                  tabIndex={0}
                  testId="mobile-navigation-item"
                />
                <NavigationItem
                  href="/travel"
                  linkDisplay="Travel"
                  onClick={this.handleClickTravel}
                  role="button"
                  tabIndex={0}
                  testId="mobile-navigation-item"
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

NavigationBar.contextType = RouterContext;
