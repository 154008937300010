import 'isomorphic-fetch';
import type { Units } from 'types/units';
import type { BatchSpotReportViews, PopularSpotReportViews } from 'types/spot';
import baseFetch, { createParamString } from '../baseFetch';
import config from '../../config';
import addClientIpHeader from '../../utils/addClientIpHeader';
import addGeoCountryCodeHeader from '../../utils/addGeoCountryCodeHeader';

export const fetchReport = ({
  clientIp = null,
  cookies,
  countryCode = null,
  spotId,
}: {
  clientIp?: string | null;
  cookies?: Record<string, string>;
  countryCode?: string | null;
  spotId: string;
}) => {
  const clientIpHeader = addClientIpHeader(clientIp);
  const countryCodeHeader = addGeoCountryCodeHeader(countryCode);
  return baseFetch(`/kbyg/spots/reports?${createParamString({ spotId })}`, {
    cookies,
    headers: {
      ...clientIpHeader,
      ...countryCodeHeader,
    },
  });
};

export const fetchNearbySpots = (
  spotId: string,
  cookies: Record<string, string>,
  clientIp = null,
  countryCode = null,
) => {
  const clientIpHeader = addClientIpHeader(clientIp);
  const countryCodeHeader = addGeoCountryCodeHeader(countryCode);
  return baseFetch(`/kbyg/spots/nearby?${createParamString({ spotId })}`, {
    cookies,
    headers: {
      ...clientIpHeader,
      ...countryCodeHeader,
    },
  });
};

export const fetchSpotDetails = (spotId: string, cookies: Record<string, string>) =>
  baseFetch(`/kbyg/spots/details?spotId=${spotId}`, { cookies });

export const fetchBuoys = async (legacySpotId: string, units: Units) => {
  const url = `${config.legacyApi}/mobile/nearby/${legacySpotId}?resources=buoy&unit=${units}&buoys=6`;
  const response = await fetch(url);
  const body = await response.json();
  if (response.status > 200) throw body;
  return body;
};

export const fetchBatchSubregions = (subregionIds: Array<string>) =>
  baseFetch(`/subregions/batch?${createParamString({ subregionIds })}`);

export const fetchNearestSpot = (lat: number, lon: number) =>
  baseFetch(`/kbyg/mapview/spot?${createParamString({ lat, lon })}`);

export const fetchRegionalConditionForecast = (subregionId: string, days: number) =>
  baseFetch(`/kbyg/regions/forecasts/conditions?${createParamString({ subregionId, days })}`);

export const fetchRegionalOverview = (subregionId: string) =>
  baseFetch(`/kbyg/regions/overview?${createParamString({ subregionId })}`);

export const fetchFavoriteSubregions = (cookies: Record<string, string>) =>
  baseFetch('/kbyg/favorites/subregions?sortBy=alphaDesc', { cookies });

interface FetchSpotReportViewsData {
  units:
    | {
        swellHeight?: string;
        temperature?: string;
        tideHeight?: string;
        waveHeight?: string;
        windSpeed?: string;
        surfHeight?: string;
      }
    | undefined;
  noCache?: string;
}

interface FetchSpotReportViewsOptions {
  method: string;
  headers: {
    Accept: string;
    'Content-Type': string;
    'Cache-Control'?: string;
  };
}

export const fetchSpotReportViews = async (
  spotIds: Array<string>,
  data: FetchSpotReportViewsData = {
    units: undefined,
    noCache: 'false',
  },
): Promise<BatchSpotReportViews> => {
  let cacheEnabled = !!(
    data.units?.surfHeight &&
    data.units?.swellHeight &&
    data.units?.temperature &&
    data.units?.tideHeight &&
    data.units?.windSpeed
  );

  const options: FetchSpotReportViewsOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (data?.noCache?.toLowerCase() === 'true') {
    options.headers['Cache-Control'] = 'no-cache';
    cacheEnabled = false;
  }

  let url = `/kbyg/spots/batch?${createParamString({
    cacheEnabled,
    'units[swellHeight]': data.units?.swellHeight ?? null,
    'units[temperature]': data.units?.temperature ?? null,
    'units[tideHeight]': data.units?.tideHeight ?? null,
    'units[waveHeight]': data.units?.surfHeight ?? null,
    'units[windSpeed]': data.units?.windSpeed ?? null,
  })}`;

  // Only add spotIds query param if there are any passed in.
  if (spotIds.length > 0) {
    // Sort the spot ids alphanumerically so they match the server response.
    // This ensures caching.
    const sortedSpotIds = spotIds.sort((a, b) =>
      a.localeCompare(b, undefined, {
        numeric: true,
        sensitivity: 'base',
      }),
    );
    url = `${url}&${createParamString({ spotIds: sortedSpotIds })}`;
  }

  return baseFetch(url, options);
};

export const fetchPopularSpots = async (geoCountryIso?: string): Promise<PopularSpotReportViews> =>
  baseFetch(`/kbyg/spots/popular?geoCountryIso=${geoCountryIso}`);
