import { useCallback, useEffect, useState } from 'react';
import { useMount, useUnmount } from 'react-use';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import { getWindow } from '@surfline/web-common';

import config from 'config';
import usePreferredForecastView from 'hooks/usePreferredForecastView';
import { useUserPreferredForecastView } from 'selectors/user';
import { spotReportPath } from 'utils/urls';

const DEBOUNCE_REPLACE = 0;

const replaceHistory = (data: any, unused: string, url?: string | URL | null) => {
  const win = getWindow();
  if (win) {
    win.history.replaceState(data, unused, url);
  }
};

export const debounceHistoryReplace = debounce(replaceHistory, DEBOUNCE_REPLACE);

// The purpose of this function is to mark any desired key value pairs of the router query as having a value
// of undefined. This is used in this file to do this to all query params if the spotId changes from a user
// navigating to a new spot.
export const clearedRouterQuery = (obj: any) => {
  const entries = Object.entries(obj);
  const undefinedEntries = entries.map(([key, value]) => {
    if (key === 'spotId' || key === 'spotSlug') {
      return [key, value];
    }
    return [key, undefined];
  });
  return Object.fromEntries(undefinedEntries);
};

export const getKbygQueryParams = (queryObject: Record<string, string | boolean> | {}) =>
  Object.fromEntries(Object.entries(queryObject).filter(([key]) => key.includes('kbyg_')));

export type Props = {
  cameras: Array<any>;
  isAnonymous: boolean | undefined;
  isMultiCam: boolean | undefined;
  isWavePool?: boolean;
  primaryCamId: string | undefined;
  shouldUpdateUrlCamId?: boolean;
  spotId: string;
  spotName: string;
};

const useConstructSpotPageURL = ({
  cameras,
  isAnonymous,
  isMultiCam,
  isWavePool,
  primaryCamId,
  shouldUpdateUrlCamId = true,
  spotId,
  spotName,
}: Props) => {
  const win = getWindow();
  const router = useRouter();
  const preferredForecastView = useUserPreferredForecastView();
  const { preferredForecastViewLocalStorage } = usePreferredForecastView(!!isAnonymous);
  const [isRouting, setIsRouting] = useState(false);

  const queryParams =
    spotId === router?.query?.spotId ? router?.query : clearedRouterQuery(router?.query);

  const setSpotUrlAndHistory = useCallback(
    ({ camIdValue }: { camIdValue: string | undefined }) => {
      const { asPath } = router;
      const { hash, search } = new URL(asPath, config.surflineHost);
      const { state } = win.history;
      const searchParams = new URLSearchParams(search);
      const spotUrl = spotReportPath(
        {
          ...queryParams,
          camId: camIdValue,
          view:
            searchParams.get('view')?.toLowerCase() === 'table' ||
            (isAnonymous && preferredForecastViewLocalStorage !== null
              ? preferredForecastViewLocalStorage === 'TABLE'
              : preferredForecastView === 'TABLE')
              ? 'table'
              : undefined,
        },
        {
          _id: spotId,
          name: spotName,
          isWavePool,
        },
      );
      const newUrl = `${spotUrl}${hash}`;
      // only update the URL if we are not currently routing in Next
      if (!isRouting) debounceHistoryReplace({ ...state, as: newUrl, url: newUrl }, '', newUrl);
    },
    [
      isAnonymous,
      isRouting,
      isWavePool,
      preferredForecastView,
      preferredForecastViewLocalStorage,
      queryParams,
      router,
      spotId,
      spotName,
      win,
    ],
  );

  // This effect invokes setSpotUrlAndHistory and it is what takes care of almost all of our URL construction.
  useEffect(() => {
    if (!win) return () => {};
    // add camId to URL, we have multiple cams and one is selected
    if (!isMultiCam && cameras.length > 1 && shouldUpdateUrlCamId) {
      setSpotUrlAndHistory({ camIdValue: primaryCamId || cameras?.[0]?._id });
      return () => {
        debounceHistoryReplace.cancel();
      };
    }
    // drop camId from URL, we're on multicam view
    if (shouldUpdateUrlCamId) {
      setSpotUrlAndHistory({ camIdValue: undefined });
    }
    return () => {
      debounceHistoryReplace.cancel();
    };
  }, [
    cameras,
    isAnonymous,
    isMultiCam,
    preferredForecastView,
    preferredForecastViewLocalStorage,
    primaryCamId,
    setSpotUrlAndHistory,
    shouldUpdateUrlCamId,
    spotId,
    spotName,
    win,
  ]);

  const handleRouteChangeStart = useCallback(() => {
    setIsRouting(true);
  }, []);

  const handleRouteChangeEnd = useCallback(() => {
    setIsRouting(false);
  }, []);

  useMount(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeEnd);
  });

  useUnmount(() => {
    router.events.off('routeChangeStart', handleRouteChangeStart);
    router.events.off('routeChangeComplete', handleRouteChangeEnd);
    debounceHistoryReplace.cancel();
  });

  return null;
};

export default useConstructSpotPageURL;
