import { parseISO, format, isValid, addDays, differenceInCalendarDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

type LimitedTimePremiumPlusConfig = {
  start: string;
  end: string;
  introBannerText: string;
  outroBannerText: string;
  bannerVersion: string;
};

type ComputeDetailsResult = {
  introBannerText?: string;
  outroBannerText?: string;
  isValid: boolean;
  daysUntilEnd?: number;
  cookieExpiryDate?: Date;
  bannerVersion?: string;
};

const computeLimitedTimePremiumPlusDetails = (
  limitedTimePremiumPlusConfig: LimitedTimePremiumPlusConfig,
  dateFormat: string,
  timeZone: string,
): ComputeDetailsResult => {
  const { start, end, introBannerText, outroBannerText, bannerVersion } =
    limitedTimePremiumPlusConfig;

  if (!bannerVersion || !introBannerText || !outroBannerText) {
    return {
      isValid: false,
    };
  }

  // Validate start and end dates
  const startDateObj = parseISO(start);
  const endDateObj = parseISO(end);
  if (!isValid(startDateObj) || !isValid(endDateObj)) {
    return {
      isValid: false,
    };
  }

  // Format dates based on timezone and date format
  const formatMapping: { [key: string]: string } = {
    MDY: 'MM/dd',
    DMY: 'dd/MM',
    YMD: 'yy/MM',
  };
  const formatString = formatMapping[dateFormat] || 'MM/dd';

  const startDateZoned = utcToZonedTime(startDateObj, timeZone);
  const endDateZoned = utcToZonedTime(endDateObj, timeZone);

  const formattedStartDate = format(startDateZoned, formatString);
  const formattedEndDate = format(endDateZoned, formatString);

  // Calculate daysUntilEnd and cookieExpiryDate
  const currentDateZoned = utcToZonedTime(new Date(), timeZone);
  const daysUntilEnd = differenceInCalendarDays(endDateZoned, currentDateZoned);
  const cookieExpiryDate = addDays(endDateZoned, 1);

  // Replace placeholders in banner texts
  const updatedIntroBannerText = introBannerText
    .replace(/\[start_date\]/g, formattedStartDate)
    .replace(/\[end_date\]/g, formattedEndDate);
  const updatedOutroBannerText = outroBannerText
    .replace(/\[start_date\]/g, formattedStartDate)
    .replace(/\[end_date\]/g, formattedEndDate);

  return {
    introBannerText: updatedIntroBannerText,
    outroBannerText: updatedOutroBannerText,
    isValid: true,
    daysUntilEnd,
    cookieExpiryDate,
    bannerVersion,
  };
};

export default computeLimitedTimePremiumPlusDetails;
