import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useUnmount } from 'react-use';
import { canUseDOM } from '@surfline/web-common';

import GoogleDFP from 'components/GoogleDFP';
import loadAdConfig from 'utils/adConfig';
import writeDebugLog from 'utils/writeDebugLog';

export interface HeaderAdProps {
  isVisible?: boolean;
  adConfigName?: string;
  adTarget?: any;
  entitlements?: any[] | undefined;
  styles?: {};
  userDetails?: any;
}
export const HeaderAd: React.FC<HeaderAdProps> = ({
  isVisible = true,
  adConfigName = '',
  adTarget = [],
  entitlements = [],
  styles = {},
  userDetails = false,
}) => {
  const getHeaderAdElement = () => {
    let headerAdElement = null;
    if (canUseDOM) {
      headerAdElement = document?.getElementById('sl-header-ad');
      if (Object.keys(styles).length > 0 && headerAdElement) {
        Object.assign(headerAdElement.style, styles);
      }
      return headerAdElement;
    }
    return headerAdElement;
  };

  const headerAdElement = getHeaderAdElement();

  useEffect(() => {
    writeDebugLog(
      `HeaderAd  - useEffect=${adConfigName} headerAdElementExists=${!!headerAdElement} isVisible=${isVisible} skipRendering=${
        !headerAdElement || !isVisible
      }`,
    );
    if (headerAdElement) {
      ReactDOM.render(
        isVisible ? (
          <GoogleDFP
            adConfig={loadAdConfig(adConfigName, adTarget, entitlements, userDetails, null)}
          />
        ) : (
          []
        ),
        headerAdElement,
      );
    }
  }, [isVisible, headerAdElement, adConfigName, adTarget, entitlements, userDetails]);

  useUnmount(() => {
    writeDebugLog(
      `HeaderAd  - unmount adConfigName=${adConfigName} headerAdElementExists=${!!headerAdElement}`,
    );
    if (headerAdElement) {
      ReactDOM.unmountComponentAtNode(headerAdElement);
    }
  });

  return null;
};

export default HeaderAd;
