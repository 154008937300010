import { getUserDetails, getUserSettings } from '@surfline/web-common';
import { createSelector } from '@reduxjs/toolkit';
import { useAppSelector } from 'stores/hooks';
import type { Permission } from 'types/permissions';
import { PermissionTypes } from 'types/permissions';
import { useTreatments, parseTreatmentWithConfig } from 'utils/treatments';
import { SL_WEB_PREMIUMPLUS_LIMITED_TIME_ACCESS } from 'common/treatments';
import computeLimitedTimePremiumPlusEligibility from 'utils/computeLimitedTimePremiumPlusEligibility';
import { type AppState } from 'stores';

/**
 * These selectors are meant to abstract redux away from the component as we start to move away from
 * it. This way when we migrate off redux we change internals of the selectors and the data will flow as
 * we expect into the consumers. This allows us to control one source of truth for data coming from
 * redux.
 */

export const getUserWasLoggedOut = (state: AppState) => state.backplane?.user?.wasLoggedOut;
export const getUserPermissions = (state: AppState) => state.backplane?.user?.permissions;
export const getUserCountryCode = (state: AppState) => state.backplane?.user?.countryCode;

export const getAllUserPermissions = createSelector(
  [
    getUserPermissions,
    (_: any, isLimitedTimePremiumPlusEligible: boolean) => isLimitedTimePremiumPlusEligible,
  ],
  (permissions: Permission[], isLimitedTimePremiumPlusEligible: boolean) => {
    const findPermission = (permissionName: PermissionTypes) =>
      permissions?.find((item: Permission) => item?.name === permissionName);

    return {
      hasAdFreePermissions: !!findPermission(PermissionTypes.AD_FREE),
      hasAdFreeCamPermissions: !!findPermission(PermissionTypes.AD_FREE_CAMS),
      hasBuoySpectraPermissions: !!findPermission(PermissionTypes.BUOY_SPECTRA),
      hasCamInsightPermissions:
        isLimitedTimePremiumPlusEligible || !!findPermission(PermissionTypes.CAM_INSIGHTS),
      hasCamRewindPermissions: !!findPermission(PermissionTypes.CAM_REWINDS),
      hasCoreForecastPermissions: !!findPermission(PermissionTypes.CORE_16DAY_FORECAST),
      hasCustomForecastPermissions: !!findPermission(PermissionTypes.CUSTOM_FORECAST),
      hasExtendedTidesPermissions: !!findPermission(PermissionTypes.EXTENDED_TIDES),
      hasExtendedChartLayerPermissions: !!findPermission(PermissionTypes.EXTENDED_CHART_LAYERS),
      hasGraphRewindPermissions:
        isLimitedTimePremiumPlusEligible || !!findPermission(PermissionTypes.GRAPH_REWINDS),
      hasHistoricForecastPermissions: !!findPermission(PermissionTypes.HISTORIC_FORECAST),
      hasMultiCamPermissions: !!findPermission(PermissionTypes.MULTI_CAM),
      hasLineupForecastPermissions: !!findPermission(PermissionTypes.LINEUP),
      hasLiveWindPermissions: !!findPermission(PermissionTypes.LIVE_WIND),
      hasPremiumCamPermissions: !!findPermission(PermissionTypes.HIGH_VALUE_CAMERA_ACCESS),
      hasPremiumAnalysisPermissions: !!findPermission(PermissionTypes.PREMIUM_ANALYSIS),
      hasSmartClipsPermissions:
        isLimitedTimePremiumPlusEligible || !!findPermission(PermissionTypes.SMART_CLIPS),
      hasSurfReportPermissions: !!findPermission(PermissionTypes.SURF_REPORT_TEXT),
      hasSwellSpectraPermissions: !!findPermission(PermissionTypes.SWELL_SPECTRA),
      hasWaveConsistencyPermissions: !!findPermission(PermissionTypes.WAVE_CONSISTENCY),
      hasWaveEnergyPermissions: !!findPermission(PermissionTypes.WAVE_ENERGY),
    };
  },
);

export const getUserIsEntitled = createSelector(
  getUserPermissions,
  (permissions: Permission[]) =>
    !!permissions?.find((permission: Permission) => permission?.name?.slice(0, 2) === 'sl'),
);

/**
 * Custom hook that returns the entitlement status of the current user.
 * @returns {boolean} The entitlement status of the user. True if the user is entitled (currently paid), false otherwise (currently free).
 */
export const useUserEntitlementStatus = () => useAppSelector(getUserIsEntitled);

export const useUserPermissionStatus = () => {
  const treatments = useTreatments();
  let isLimitedTimePremiumPlusEligible = false;
  // Check if treatments are defined and not empty
  if (treatments && Object.keys(treatments)?.length > 0) {
    const limitedTimePremiumPlusTreatment = treatments[SL_WEB_PREMIUMPLUS_LIMITED_TIME_ACCESS];
    // Check if the treatment is defined and valid
    if (limitedTimePremiumPlusTreatment) {
      const limitedTimePremiumPlusConfig = parseTreatmentWithConfig(
        limitedTimePremiumPlusTreatment,
      );
      isLimitedTimePremiumPlusEligible = computeLimitedTimePremiumPlusEligibility(
        limitedTimePremiumPlusConfig,
      );
    }
  }
  const permissions = useAppSelector((state: any) =>
    getAllUserPermissions(state, isLimitedTimePremiumPlusEligible),
  );
  return permissions;
};

export const useUserDetails = () => useAppSelector(getUserDetails);

export const useUserSettings = () => useAppSelector(getUserSettings);

export const useUserCountryCode = () => useAppSelector(getUserCountryCode);

export const useUserUnits = () => {
  const units = useAppSelector(getUserSettings)?.units;
  return units;
};

export const useUserDateFormat = () => {
  const dateFormat = useAppSelector(getUserSettings)?.date?.format;
  return dateFormat;
};

export const useUserPreferredForecastView = () => {
  const preferredForecastView = useAppSelector(getUserSettings)?.preferredForecastView;
  return preferredForecastView;
};
