import { getFavoriteSubregions, getNearestSubregion, getUserFavorites } from '@surfline/web-common';
import { fetchRegionalArticleById, fetchRegionalArticles } from '../common/api/feed';
import {
  fetchBatchSubregions,
  fetchNearestSpot,
  fetchRegionalOverview,
  fetchRegionalConditionForecast,
} from '../common/api/spot';
import { redirectToNotFound } from './status';
import sanitizeArticleHTML from '../utils/sanitizeArticleHTML';

export const FETCH_FORECAST_ARTICLE = 'FETCH_FORECAST_ARTICLE';
export const FETCH_FORECAST_ARTICLE_SUCCESS = 'FETCH_FORECAST_ARTICLE_SUCCESS';
export const FETCH_FORECAST_ARTICLE_FAILURE = 'FETCH_FORECAST_ARTICLE_FAILURE';

export const FETCH_FORECAST_ARTICLES = 'FETCH_FORECAST_ARTICLES';
export const FETCH_FORECAST_ARTICLES_SUCCESS = 'FETCH_FORECAST_ARTICLES_SUCCESS';
export const FETCH_FORECAST_ARTICLES_FAILURE = 'FETCH_FORECAST_ARTICLES_FAILURE';

export const FETCH_SUBREGION_FORECAST = 'FETCH_SUBREGION_FORECAST';
export const FETCH_SUBREGION_FORECAST_SUCCESS = 'FETCH_SUBREGION_FORECAST_SUCCESS';
export const FETCH_SUBREGION_FORECAST_FAILURE = 'FETCH_SUBREGION_FORECAST_FAILURE';

export const fetchForecastArticle =
  (id, cookies, isServer = false) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_FORECAST_ARTICLE,
    });

    try {
      const {
        data: { article },
      } = await fetchRegionalArticleById(id, cookies, isServer);
      dispatch({
        type: FETCH_FORECAST_ARTICLE_SUCCESS,
        article: {
          ...article,
          content: {
            ...article.content,
            body: sanitizeArticleHTML(article.content.body),
          },
        },
      });
    } catch (error) {
      if (error.statusCode === 400) {
        dispatch(redirectToNotFound('The article was not found'));
      } else {
        dispatch({
          type: FETCH_FORECAST_ARTICLE_FAILURE,
          error,
        });
      }
    }
  };

export const fetchForecastArticles =
  (subregionId, isServer = false) =>
  async (dispatch, getState) => {
    dispatch({
      type: FETCH_FORECAST_ARTICLES,
    });
    try {
      const { nextStart } = getState().forecast.articles;
      const articles = await fetchRegionalArticles(subregionId, nextStart, 6, true, isServer);
      dispatch({
        type: FETCH_FORECAST_ARTICLES_SUCCESS,
        articles,
      });
    } catch (error) {
      dispatch({
        type: FETCH_FORECAST_ARTICLES_FAILURE,
        error,
      });
    }
  };

export const fetchSubregionForecast = (subregion) => async (dispatch, getState) => {
  dispatch({
    type: FETCH_SUBREGION_FORECAST,
  });
  try {
    const state = getState();
    let subregionId;
    let favoriteSubregions = {};
    const favorites = getUserFavorites(state);

    if (favorites.length) {
      favoriteSubregions = await getFavoriteSubregions(favorites, fetchBatchSubregions);
    }
    const subregions = favoriteSubregions.subregions || [];

    if (subregion) subregionId = subregion;
    else {
      subregionId =
        favoriteSubregions.topSubregion || (await getNearestSubregion(fetchNearestSpot));
    }
    const filteredSubregions = subregions.filter((sub) => sub._id !== subregionId);

    const [overview, forecast] = await Promise.all([
      fetchRegionalOverview(subregionId),
      fetchRegionalConditionForecast(subregionId, 2),
    ]);

    await dispatch(fetchForecastArticles(subregionId));

    dispatch({
      type: FETCH_SUBREGION_FORECAST_SUCCESS,
      overview,
      forecast,
      subregions: filteredSubregions,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SUBREGION_FORECAST_FAILURE,
      error,
    });
  }
};
