import { createAction } from '@reduxjs/toolkit';
import * as regionApi from '../common/api/region';
import { internalServerError, redirectToNotFound } from './status';
import sanitizeArticleHTML from '../utils/sanitizeArticleHTML';

export const SET_SUBREGION_LOADING = 'SET_SUBREGION_LOADING';

/** @type {import('@reduxjs/toolkit').ActionCreatorWithOptionalPayload<boolean>} */
export const setSubregionLoading = createAction(SET_SUBREGION_LOADING);

export const CLEAR_SUBREGION_OVERVIEW_DATA = 'CLEAR_SUBREGION_OVERVIEW_DATA';
export const FETCH_SUBREGION_OVERVIEW_DATA = 'FETCH_SUBREGION_OVERVIEW_DATA';
export const FETCH_SUBREGION_OVERVIEW_DATA_SUCCESS = 'FETCH_SUBREGION_OVERVIEW_DATA_SUCCESS';
export const FETCH_SUBREGION_OVERVIEW_DATA_FAILURE = 'FETCH_SUBREGION_OVERVIEW_DATA_FAILURE';

export const FETCH_REGIONAL_ARTICLES = 'FETCH_REGIONAL_ARTICLES';
export const FETCH_REGIONAL_ARTICLES_SUCCESS = 'FETCH_REGIONAL_ARTICLES_SUCCESS';
export const FETCH_REGIONAL_ARTICLES_FAILURE = 'FETCH_REGIONAL_ARTICLES_FAILURE';

/**
 * @param { string } subregionId
 * @param {function} callback
 * @param {Record<string, string | string[]>} cookies
 * @param {string | undefined | null} [clientIp]
 * @param { boolean | undefined } shouldFiveHundred
 * @param { string | undefined | null } countryCode
 */
export const fetchOverviewData =
  (
    subregionId,
    cookies = undefined,
    clientIp = undefined,
    shouldFiveHundred = true,
    countryCode = null,
  ) =>
  async (dispatch) => {
    let overview;
    dispatch({
      type: FETCH_SUBREGION_OVERVIEW_DATA,
      subregionId,
    });

    if (!subregionId) {
      dispatch(
        redirectToNotFound({
          message: 'The Regional Forecast Overview could not be found',
        }),
      );
    }

    try {
      overview = await regionApi.fetchRegionalOverview(subregionId, cookies, clientIp, countryCode);
      dispatch({
        type: FETCH_SUBREGION_OVERVIEW_DATA_SUCCESS,
        subregionId,
        overview,
      });
    } catch (err) {
      dispatch({
        type: FETCH_SUBREGION_OVERVIEW_DATA_FAILURE,
        subregionId,
      });
      if (err.statusCode === 400 || err.statusCode === 404 || !overview) {
        dispatch(redirectToNotFound('The Regional Forecast Overview could not be found'));
      } else if (shouldFiveHundred) {
        dispatch(internalServerError('The server could not return the Regional Forecast Overview'));
      }
    }
  };

export const clearOverviewData = () => ({
  type: CLEAR_SUBREGION_OVERVIEW_DATA,
});

export const fetchRegionalArticles =
  (subregionId, cookies, isServer = false) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_REGIONAL_ARTICLES,
    });

    try {
      const { data } = await regionApi.fetchRegionalArticles(subregionId, cookies, isServer);
      const sanitizedArticles = data.regional.map((article) => ({
        ...article,
        content: {
          ...article.content,
          body: sanitizeArticleHTML(article.content.body),
        },
      }));
      dispatch({
        type: FETCH_REGIONAL_ARTICLES_SUCCESS,
        articles: sanitizedArticles,
      });
    } catch (error) {
      dispatch({
        type: FETCH_REGIONAL_ARTICLES_FAILURE,
        error: error.message,
      });
    }
  };

export const FETCH_NEARBY_SUBREGIONS = 'FETCH_NEARBY_SUBREGIONS';
export const FETCH_NEARBY_SUBREGIONS_SUCCESS = 'FETCH_NEARBY_SUBREGIONS_SUCCESS';
export const FETCH_NEARBY_SUBREGIONS_FAILURE = 'FETCH_NEARBY_SUBREGIONS_FAILURE';

export const fetchNearbySubregions = (subregionId, cookies) => async (dispatch) => {
  dispatch({ type: FETCH_NEARBY_SUBREGIONS });
  try {
    const nearbySubregions = await regionApi.fetchNearbySubregions(subregionId, cookies);
    dispatch({
      type: FETCH_NEARBY_SUBREGIONS_SUCCESS,
      nearbySubregions,
    });
  } catch (err) {
    dispatch({
      type: FETCH_NEARBY_SUBREGIONS_FAILURE,
      error: err.message,
    });
  }
};
