import React from 'react';

export const SurflineWaterDrop = ({ className }: { className?: string }) => (
  <svg className={className} width="20px" height="26px" viewBox="0 0 20 26">
    <g id="logged-in-premium" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="iphone-5-copy" transform="translate(-13.000000, -132.000000)" fill="#FFFFFF">
        <g id="nav" transform="translate(0.000000, 124.000000)">
          <g id="Group-3" transform="translate(13.000000, 8.000000)">
            <path d="M9.6377485,22.5581045 C9.09897796,22.5505969 8.5778841,22.4751447 8.08220047,22.3385049 C10.7808406,21.6680692 12.7992967,19.2059258 12.8383327,16.2482757 C12.8490124,15.3698772 13.5560797,14.6675338 14.4181862,14.6802969 C15.2780831,14.6923092 15.9663689,15.412671 15.9549527,16.2888171 C15.9078149,19.7997832 13.0802823,22.6046521 9.6377485,22.5581045 L9.6377485,22.5581045 Z M5.0259758,17.7295407 C4.16423755,17.7164023 3.47558348,16.9979174 3.48736793,16.1202697 C3.53376922,12.609679 6.36130182,9.80368395 9.80457218,9.85135772 C10.3422379,9.85736386 10.8640683,9.93506834 11.3608567,10.0720835 C8.66111181,10.7406423 6.64339221,13.2005333 6.60398794,16.1619374 C6.59294001,17.039585 5.88587273,17.741553 5.0259758,17.7295407 L5.0259758,17.7295407 Z M18.4304248,12.0623692 C17.3525154,9.24661417 9.72023968,0.0515842315 9.72023968,0.0515842315 C9.72023968,0.0515842315 2.08685915,9.24661417 1.0100546,12.0623692 C0.432248053,13.3206562 0.108543813,14.7242168 0.108543813,16.2032296 C0.108543813,21.61514 4.4117111,26 9.72023968,26 C15.0284,26 19.331199,21.61514 19.331199,16.2032296 C19.331199,14.7242168 19.0074948,13.3206562 18.4304248,12.0623692 L18.4304248,12.0623692 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SurflineWaterDrop;
