const isPremiumOrVIP = (entitlements: Array<string> | undefined) => {
  if (entitlements && entitlements.length) {
    return (
      entitlements.includes('sl_premium') ||
      entitlements.includes('sl_premium_plus') ||
      entitlements.includes('sl_youth') ||
      entitlements.includes('sl_vip') ||
      entitlements.includes('sl_vip_advertiser')
    );
  }
  return false;
};

export default isPremiumOrVIP;
