const isPremiumEntitled = (entitlements: Array<string> | undefined) => {
  if (entitlements && entitlements.length) {
    return entitlements.includes('sl_premium') && !entitlements.includes('sl_premium_plus');
  }
  return false;
};

export const isPremiumPlusEntitled = (entitlements: Array<string> | undefined) => {
  if (entitlements && entitlements.length) {
    return entitlements.includes('sl_premium_plus');
  }
  return false;
};

export default isPremiumEntitled;
