import addClientIpHeader from 'utils/addClientIpHeader';
import addCustomUserAgentHeader from 'utils/addCustomUserAgentHeader';
import addGeoCountryCodeHeader from 'utils/addGeoCountryCodeHeader';
import baseFetch, { createParamString } from '../baseFetch';

export const fetchRegionalOverview = (subregionId, cookies, clientIp, countryCode = null) => {
  const clientIpHeader = addClientIpHeader(clientIp);
  const countryCodeHeader = addGeoCountryCodeHeader(countryCode);
  return baseFetch(`/kbyg/regions/overview?${createParamString({ subregionId })}`, {
    cookies,
    headers: {
      ...clientIpHeader,
      ...countryCodeHeader,
    },
  });
};

export const fetchNearbySubregions = (subregionId, cookies) =>
  baseFetch(`/kbyg/regions/nearby?${createParamString({ subregionId })}`, {
    cookies,
  });

export const fetchRegionalArticles = async (
  subregionId,
  cookies,
  isServer = false,
  noCache = false,
) => {
  let cacheEnabled = true;
  const headers = {};
  if (noCache) {
    headers['Cache-Control'] = 'no-cache';
    cacheEnabled = false;
  }
  return baseFetch(`/feed/regional?${createParamString({ subregionId, cacheEnabled })}`, {
    cookies,
    headers: {
      ...headers,
      ...addCustomUserAgentHeader(isServer),
    },
  });
};
