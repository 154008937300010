import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';

import feed from './feed';
import primarySpotId from './primarySpotId';
import subregionId from './subregionId';
import overview from './overview';
import nearby from './nearby';
import { setSubregionLoading } from '../../actions/subregion';

export default combineReducers({
  feed,
  primarySpotId,
  subregionId,
  overview,
  nearby,
  loading: createReducer(false, (builder) => {
    builder.addCase(setSubregionLoading, (state, action) => action.payload);
  }),
});
