import en from 'intl/translations/en';
import { Location } from 'propTypes/map/location';
import { SubregionOverviewData } from 'types/subregion';
import subregionForecastChartsPath from 'utils/urls/forecastChartsPath';
import {
  spotForecastChartsPath,
  spotGuidePath,
  spotReportPath,
  subregionForecastPath,
} from 'utils/urls';

export type PageType = 'charts' | 'spot_charts' | 'regional analysis' | 'spot' | 'spot_guide';

export const PageTypes = {
  CHARTS: 'charts',
  REGIONAL_ANALYSIS: 'regional analysis',
  SPOT: 'spot',
  SPOT_GUIDE: 'spot_guide',
  SPOT_CHARTS: 'spot_charts',
};

export const usePageDetails = (
  numForecastArticles: number,
  hasLiveCam: boolean,
  pageType: PageType,
) => {
  switch (pageType) {
    case 'regional analysis':
      return { tab: 1, subtitle: `${en.RegionalForecast.header.titleSuffix}` };
    case 'spot_charts':
    case 'charts':
      return {
        tab: !numForecastArticles ? 1 : 2,
        subtitle: `${en.RegionalForecast.header.titleSuffix}`,
      };
    case 'spot_guide':
      return {
        tab: !numForecastArticles ? 2 : 3,
        subtitle: 'Surf spot guide',
      };
    default:
      // SPOT
      return { tab: 0, subtitle: `Surf Report & ${hasLiveCam ? 'Live Cam' : 'Forecast'}` };
  }
};

const createLocationObject = (lat: number = 0, lon: number = 0, zoom: number = 7): Location => ({
  center: {
    lat,
    lon,
  },
  zoom,
});

export const usePageLinks = (
  numForecastArticles: number,
  spotName?: string,
  spotId?: string,
  spotLat?: number,
  spotLon?: number,
  subregionOverview?: SubregionOverviewData,
  tableView: boolean = false,
  isRegionalCharts: boolean = false,
) => {
  /*
    SpotId & name are optional when in loading state here because react will call the hook
    we need to deal with this. It shouldnt impact anything as it will only render this whilst
    its loading and update when it has the spot details.
  */
  const DEFAULT_ZOOM = 7;
  const pageLinks = [
    {
      text: en.SpotPage.forecastHeaderLinkText.spot,
      path: spotReportPath(
        { view: tableView ? 'table' : undefined },
        { _id: spotId || '', name: spotName || 'spot' },
      ),
      newWindow: false,
    },
  ];

  if (numForecastArticles > 0 && subregionOverview?._id) {
    pageLinks.push({
      text: en.SpotPage.forecastHeaderLinkText.regionalAnalysis,
      path: subregionForecastPath(subregionOverview._id, subregionOverview.name, spotId),
      newWindow: false,
    });
  }
  if (subregionOverview && isRegionalCharts) {
    const { name, _id: id, spots } = subregionOverview;
    const { lat, lon } =
      spots?.find((s) => s._id === subregionOverview.primarySpot) || subregionOverview.spots[0];

    const mapTilerCharts = {
      text: en.SpotPage.forecastHeaderLinkText.charts,
      path: subregionForecastChartsPath(
        id,
        name || '',
        'wave-height',
        {
          ...createLocationObject(lat, lon, DEFAULT_ZOOM),
        },
        spotId,
      ),
      newWindow: false,
    };
    pageLinks.push(mapTilerCharts);
  } else {
    const spotLocation = createLocationObject(spotLat, spotLon, DEFAULT_ZOOM);
    pageLinks.push({
      text: en.SpotPage.forecastHeaderLinkText.charts,
      path: spotForecastChartsPath(spotId || '', spotName || '', 'wave-height', spotLocation),
      newWindow: false,
    });
  }

  const guideRoute = {
    text: en.SpotPage.forecastHeaderLinkText.guide,
    path: spotGuidePath({ spotName: spotName || '', spotId: spotId || '' }),
    newWindow: false,
  };
  pageLinks.push(guideRoute);

  return pageLinks;
};
