import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'lodash';
import { useRouter } from 'next/router';
import { useMount, useUnmount } from 'react-use';
import { getWindow } from '@surfline/web-common';

import HeaderAd from 'components/HeaderAd';
import useMutationObserver from 'hooks/useMutationObserver';
import { useUserEntitlementStatus, useUserPermissionStatus } from 'selectors/user';
import getHeroAdConfigForPath, { HeroAdConfig } from 'utils/adConfigPaths';

const AppHeaderAd = () => {
  const router = useRouter();
  const { hasAdFreePermissions } = useUserPermissionStatus();
  const isEntitled = useUserEntitlementStatus();
  const headerAdRef = useRef<HTMLDivElement>(null);
  const [headerAdHeight, setHeaderAdHeight] = useState(0);
  const previousAdConfig = useRef<HeroAdConfig | undefined>(undefined);
  const previousAdConfigSet = useRef<boolean>(false);
  const win = getWindow();

  const activeHeroAdConfig = useMemo(() => {
    const adConfig = getHeroAdConfigForPath(router.pathname, !!router.query?.qaFlag);
    if (previousAdConfigSet.current === false) {
      previousAdConfig.current = adConfig;
      previousAdConfigSet.current = true;
    }
    return adConfig;
  }, [router.pathname, router.query?.qaFlag]);

  // Should only display on certain paths that will have a hero ad
  const showHeroAd = useMemo(() => {
    if (hasAdFreePermissions) return false;
    if (isEntitled && activeHeroAdConfig?.showToPremium) return true;
    if (!isEntitled && activeHeroAdConfig) return true;
    return false;
  }, [activeHeroAdConfig, hasAdFreePermissions, isEntitled]);

  const headerAdStyles = useMemo(() => {
    if (
      hasAdFreePermissions ||
      !activeHeroAdConfig ||
      (isEntitled && !activeHeroAdConfig?.showToPremium) ||
      !activeHeroAdConfig.reserveHeight
    ) {
      return {
        height: 'initial',
      };
    }
    return {
      height: headerAdHeight === 0 ? 'initial' : `${headerAdHeight}px`,
    };
  }, [activeHeroAdConfig, hasAdFreePermissions, headerAdHeight, isEntitled]);

  const handleRouteChangeComplete = useCallback(() => {
    if (
      previousAdConfig.current &&
      win.blogherads?.reloadAds &&
      activeHeroAdConfig?.adConfigName === previousAdConfig.current.adConfigName
    ) {
      win.blogherads.reloadAds();
    }
    previousAdConfig.current = activeHeroAdConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- don't need win.blogherads as a dependency
  }, [activeHeroAdConfig]);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [handleRouteChangeComplete, router.events]);

  const headerAdClassNames = useMemo(() => {
    // shows ad area with reserved height as ad loads to avoid cumulative layout shift
    if (showHeroAd && activeHeroAdConfig?.reserveHeight !== false) return 'active';
    // passively show ad not reserving height as we are not sure if ad will load
    if (showHeroAd && activeHeroAdConfig?.reserveHeight === false) return 'passive';
    // no ad should be shown
    return '';
  }, [activeHeroAdConfig?.reserveHeight, showHeroAd]);

  const handleAdContainerSize = () => {
    if (headerAdRef?.current) {
      const childIframe = headerAdRef.current.querySelectorAll('iframe');
      if (childIframe.length > 0 && childIframe[0].offsetHeight > headerAdHeight) {
        setHeaderAdHeight(childIframe[0].offsetHeight);
      }
    }
  };

  useMutationObserver(headerAdRef, handleAdContainerSize);

  useMount(() => {
    if (win) win.addEventListener('resize', throttle(handleAdContainerSize, 250));
  });

  useUnmount(() => {
    if (win) win.removeEventListener('resize', throttle(handleAdContainerSize, 250));
  });

  return (
    <div
      id="sl-header-ad"
      data-testid="app-header-ad"
      className={headerAdClassNames}
      style={headerAdStyles}
      ref={headerAdRef}
    >
      {showHeroAd && !!activeHeroAdConfig?.adConfigName && !!activeHeroAdConfig?.adTarget && (
        <HeaderAd
          adConfigName={activeHeroAdConfig?.adConfigName}
          adTarget={activeHeroAdConfig?.adTarget}
        />
      )}
    </div>
  );
};

export default AppHeaderAd;
