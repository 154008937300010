import React from 'react';

interface Props {
  className?: string;
}

export const PlayIcon = ({ className }: Props) => (
  <svg
    className={className}
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="play-icon"
  >
    <rect opacity="0.5" width="44" height="44" rx="22" fill="#171717" />
    <path fillRule="evenodd" clipRule="evenodd" d="M27.5 22L19.8 27.5V16.5L27.5 22Z" fill="white" />
  </svg>
);

export default PlayIcon;
