export interface HeroAdConfig {
  adConfigName?: string;
  adTarget?: [[string, boolean]];
  reserveHeight: boolean;
  showToPremium: boolean;
}

// There are several paths in our code which handle the rendering of the hero ad differently from the paths listed below.
// These as of December of 2023 these paths are for: Home, Editorial and Swell Pages.
const getHeroAdConfigForPath = (
  pathname: string,
  qaFlag: boolean = false,
): HeroAdConfig | undefined => {
  switch (pathname) {
    case '/':
      return {
        adConfigName: 'homepageHeroAd',
        adTarget: [['qaFlag', qaFlag]],
        reserveHeight: false,
        showToPremium: true,
      };
    case '/surf-forecasts/[subregionSlug]/[subregionId]':
    case '/surf-report/[spotSlug]/[spotId]':
    case '/surf-report/[spotSlug]/[spotId]/clips':
    case '/surf-report/[spotSlug]/[spotId]/spot-charts/[locationZoomSlug]':
    case '/surf-report/[spotSlug]/[spotId]/spot-guide':
    case '/wave-pool/[slug]/[id]':
      return {
        adConfigName: 'slKbygHorizontalVariable',
        adTarget: [['qaFlag', qaFlag]],
        reserveHeight: true,
        showToPremium: false,
      };
    case '/category':
    case '/category/[...terms]':
    case '/series':
    case '/series/[...terms]':
    case '/surf-news':
    case '/surf-news/[slug]/[id]':
    case '/surf-news/local/[slug]/[id]':
    case '/surf-news/swell-alert/[slug]/[id]':
    case '/tag':
    case '/tag/[...terms]':
      // reserve ad space but use the HeaderAd from the page's container template
      // we don't render the HeaderAd when adConfigName and adTarget is not provided, only render the container
      return {
        reserveHeight: true,
        showToPremium: false,
      };
    case '/travel':
    case '/travel/[...geonames]':
    case '/travel/directory':
    case '/travel/zones/[slug]/[id]':
    case '/travel/zones/[slug]/surf-guide/[spot]/[id]':
      return {
        adConfigName: 'SL_Travel_Horizontal_Variable_NoProgrammatic',
        adTarget: [['qaFlag', qaFlag]],
        reserveHeight: true,
        showToPremium: false,
      };
    default:
      return undefined;
  }
};

export default getHeroAdConfigForPath;
