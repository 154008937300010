import { slugify } from '@surfline/web-common';

export const subregionForecastPaths = {
  base: '/surf-forecasts',
};

const subregionForecastPath = (subregionId, subregionName, spotId) =>
  `${subregionForecastPaths.base}/${slugify(subregionName)}/${subregionId}${
    spotId ? `?spotId=${spotId}` : ''
  }`;

export default subregionForecastPath;
